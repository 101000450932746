import toiletNeroCartier105 from "../images/Toilet Nero Cartier (105x56x62)-1.jpg";
import toiletNeroCartier105Marbel from "../marbels/Toilet Nero Cartier (105x56x62).png";

import toiletneroGalaxy from "../images/Toilet Nero Galaxy(73x118x9)(Others).jpg";
import toiletneroGalaxyMarbel from "../marbels/Toilet Nero Galaxy(73x118x9)(Others).png";

import toiletNsl from "../images/Toilet NSL-1(1).jpg";
import toiletNslMarbel from "../marbels/Toilet NSL-1.jpg";

import toiletPlatinum from "../images/Toilet Platinum Portoro.jpg";
import toiletPlatinumMarbel from "../marbels/Toilet Platinum Portoro(1).jpg";

import toiletSatuario from "../images/Toilet Statuario Oro(111x73x66).jpg";
import toiletSatuarioMarbel from "../marbels/Toilet Statuario Oro(111x73x66).png";

import WallToiletMachiVachi from "../images/Wall Toilet Machi Vachi (1).jpg";
import WallToiletMachiVachiMarbel from "../marbels/Wall Toilet Machi Vachi.jpg";

import WallToiletMartingrey from "../images/Wall Toilet Martin Grey(113x76x63).jpg";
import WallToiletMartingreyMarbel from "../marbels/Wall Toilet Martin Grey(113x76x63) (1).jpg";

import WallToiletDolche from "../images/Wall Toilet Dolche Grey(65x108x28).jpg";
import WallToiletDolcheMarbel from "../marbels/Wall Toilet Dolche Grey(65x108x28).jpeg";

import ToiletFeatherGrey from "../images/Toilet Feather Grey(71x57x29)-Grey.jpg";
import ToiletFeatherGreyMarbel from "../marbels/Toilet Feather Grey(71x57x29)-Grey.png";

import toiletGokgrey from "../images/Toilet GOK grey 104x64x55 (1).jpg";
import toiletGokgreyMarbel from "../marbels/Toilet GOK grey 104x64x55.jpg";

import toiletgreyOrobico from "../images/Toilet Grey Orobico (73x53x53)_.jpg";
import toiletgreyOrobicoMarbel from "../marbels/Toilet Grey Orobico (73x53x53) .png";

import toiletgrigioSmoke from "../images/Toilet Grigio Smoke (124x76)(Grey)_ (1).jpg";
import toiletgrigioSmokeMarbel from "../marbels/Toilet Grigio Smoke (124x76)(Grey)_.jpg";

import toiletMichealGrey from "../images/Toilet Michael Grey(97x61x31).jpg";
import toiletMichealGreyMarbel from "../marbels/Toilet Michael Grey(97x61x31).png";

import WalltoiletMetalRust from "../images/Wall Toilet Metal Rust(1).jpg";
import WalltoiletMetalRustMarbel from "../marbels/Wall Toilet Metal Rust.jpg";

import wallToiletPalissandro from "../images/Wall Toilet Palissandro Blue(121x66x51).jpg";
import wallToiletPalissandroMarbel from "../marbels/Wall Toilet Palissandro Blue(121x66x51).png";

import michaelengeloroom_page from "../images/michaelengeloroom_page.jpg";
import michaelengeloroomPageMarbel from "../marbels/michaelengeloroom_page.jpg";

import floorAlexgendarGrey90x78x67Marbel from "../marbels/Floor Alexander Grey 90x78x67.jpg";
import floorAlexgendarGrey90x78x67 from "../images/Floor Alexander Grey 90x78x67_.jpg";

import FloorAlexanderGreyMarbel from "../marbels/Floor Alexander Grey-2.jpg";
import FloorAlexanderGrey from "../images/Floor Alexander Grey-2_.jpg";

import FloorBiancoCarraraMarbel from "../marbels/Floor Bianco Carrara(126x70x86).jpg";
import FloorBiancoCarrara from "../images/Floor Bianco Carrara(126x70x86)_.jpg";

import FloorBiancoLasa117x78x58Marbel from "../marbels/Floor Bianco Lasa 117x78x58.jpg";
import FloorBiancoLasa117x78x58 from "../images/Floor Bianco Lasa 117x78x58_.jpg";

import FloorBiancoLasa83_x63_x47Marbel from "../marbels/Floor Bianco Lasa 83_x63_x47.jpg";
import FloorBiancoLasa83_x63_x47 from "../images/Floor Bianco Lasa 83_x63_x47_.jpg";

import FloorBiancoLasa99x80x59Marbel from "../marbels/Floor Bianco Lasa 99x80x59.jpg";
import FloorBiancoLasa99x80x59 from "../images/Floor Bianco Lasa 99x80x59_.jpg";

import FloorBiancoLasa1Marbel from "../marbels/Floor Bianco Lasa(1).jpg";
import FloorBiancoLasa1 from "../images/Floor Bianco Lasa(1)_.jpg";

import FloorBiancoLasa118x56x75Marbel from "../marbels/Floor Bianco Lasa(118x56x75).jpg";
import FloorBiancoLasa118x56x75 from "../images/Floor Bianco Lasa(118x56x75)_.jpg";

import FloorBlueBardilioMarbel from "../marbels/Floor Blue Bardilio.jpg";
import FloorBlueBardilio from "../images/Floor Blue Bardilio_.jpg";

import FloorCaspianGrey98x70x74Marbel from "../marbels/Floor Caspian Grey (98x70x74).jpg";
import FloorCaspianGrey98x70x74 from "../images/Floor Caspian Grey (98x70x74)_.jpg";

import FloorChanelGrey101x75x15Marbel from "../marbels/Floor Chanel Grey (101x75x15).jpg";
import FloorChanelGrey101x75x15 from "../images/Floor Chanel Grey (101x75x15)_.jpg";

import FloorChanelGrey108x64x90Marbel from "../marbels/Floor Chanel grey 108x64x90.jpg";
import FloorChanelGrey108x64x90 from "../images/Floor Chanel grey 108x64x90_.jpg";

import FloorCremaEuropa84x73x87Marbel from "../marbels/Floor Crema Europa 84x73x87.jpg";
import FloorCremaEuropa84x73x87 from "../images/Floor Crema Europa 84x73x87_.jpg";

import FloorCremaSiena90x62x77Marbel from "../marbels/Floor Crema Siena 90x62x77.jpg";
import FloorCremaSiena90x62x77 from "../images/Floor Crema Siena 90x62x77_.jpg";

import FloorFiorDeBoscoMarbel from "../marbels/Floor Fior De Bosco.jpg";
import FloorFiorDeBosco from "../images/Floor Fior De Bosco_.jpg";

import FloorGrigioFrescoMarbel from "../marbels/Floor Grigio Fresco.jpg";
import FloorGrigioFresco from "../images/Floor Grigio Fresco_.jpg";

import FloorLagosMarbel from "../marbels/Floor Lagos 96x67x87.jpg";
import FloorLagos from "../images/Floor Lagos 96x67x87_.jpg";

import FloorMauraniceMarbel from "../marbels/Floor Mauranice.jpg";
import FloorMauranice from "../images/Floor Mauranice_.jpg";

import FloorMediterraneanMarbel from "../marbels/Floor Mediterranean.jpg";
import FloorMediterranean from "../images/Floor Mediterranean_.jpg";

import FloorMichelAngelo125_x64_76Marbel from "../marbels/Floor Michel Angelo 125_x64_76.jpg";
import FloorMichelAngelo125_x64_76 from "../images/Floor Michel Angelo 125_x64_76_.jpg";

import FloorMidnightGrey113x79x73Marbel from "../marbels/Floor Midnight Grey 113x79x73.jpg";
import FloorMidnightGrey113x79x73 from "../images/Floor Midnight Grey 113x79x73_.jpg";

import FloorNewZealandLasa120x76Marbel from "../marbels/Floor New Zealand Lasa 120x76.jpg";
import FloorNewZealandLasa120x76 from "../images/Floor New Zealand Lasa 120x76_.jpg";

import FloorPegasus115x60x71Marbel from "../marbels/Floor Pegasus 115x60x71.jpg";
import FloorPegasus115x60x71 from "../images/Floor Pegasus 115x60x71_.jpg";

import FloorPegasusMarbel from "../marbels/Floor Pegasus.jpg";
import FloorPegasus from "../images/Floor Pegasus_.jpg";

import FloorSofiaGrey78x66x33Marbel from "../marbels/Floor Sofia Grey(78x66x33).jpg";
import FloorSofiaGrey78x66x33 from "../images/Floor Sofia Grey(78x66x33_.jpg";

import FloorStatuarioArabescatoMarbel from "../marbels/Floor Statuario Arabescato.jpg";
import FloorStatuarioArabescato from "../images/Floor Statuario Arabescato_.jpg";

import FloorStatuarioVenatoMarbel from "../marbels/Floor Statuario Venato(117x51x64).jpg";
import FloorStatuarioVenato from "../images/Floor Statuario Venato(117x51x64)_.jpg";

import FloorMichealAngeloMarbel from "../marbels/Floor Tremendous.(103x72x65) jpg.jpg";
import FloorMichealAngelo from "../images/Floor Tremendous.(103x72x65) _.jpg";

import FloorToiletAlbanianWhiteMarbel from "../marbels/Floor Toilet Albanian White.jpg";
import FloorToiletAlbanianWhite from "../images/Floor Toilet_Albanian White.jpg";

import FloorToiletArgent_GreyMarbel from "../marbels/Floor Toilet Argent Grey.jpg";
import FloorToiletArgent_Grey from "../images/Floor Toilet Argent_Grey.jpg";

import FloorToiletArmaniGreyMarbel from "../marbels/Floor Toilet Armani Grey.jpg";
import FloorToiletArmaniGrey from "../images/Floor Toilet Armani_Grey.jpg";

import FloorToiletEmalgreyMarbel from "../marbels/Floor Toilet Emal grey (117x70x48).jpg";
import FloorToiletEmalgrey from "../images/Floor Toilet Emal grey_(117x70x48).jpg";

import FloorToiletMaverick8Marbel from "../marbels/Floor Toilet Maverick (85_67_86).jpg";
import FloorToiletMaverick8 from "../images/Floor Toilet Maverick (85_67_86) (2).jpg";

import FloorToiletMaverick6Marbel from "../marbels/Floor Toilet Maverick 69x58x34.jpg";
import FloorToiletMaverick6 from "../images/Floor Toilet Maverick 69x58x34 (2).jpg";

import WallAfricanPortoroMarbel from "../marbels/Wall African Portoro.jpg";
import WallAfricanPortoro from "../images/Wall African Portoro.jpg";

import WallAndFloorArezzoMarbel from "../marbels/Wall And Floor Arezzo(83x71x62)(Others).jpg";
import WallAndFloorArezzo from "../images/Wall And Floor Arezzo(83x71x62)(Others).jpg";

import WallAtlantaGreyMarbel from "../marbels/Wall Atlanta Grey (97x61x62).jpg";
import WallAtlantaGrey from "../images/Wall Atlanta Grey (97x61x62).jpg";

import WallBlackForestMarbel from "../marbels/Wall Black Forest 120x74x17.jpg";
import WallBlackForest from "../images/Wall Black Forest 120x74x17.jpg";

import WallBlackOnyxMarbel from "../marbels/Wall Black Onyx (95x69x29).jpg";
import WallBlackOnyx from "../images/Wall Black Onyx (95x69x29).jpg";

import WallHoneyArabescatoOnyxMarbel from "../marbels/Wall Honey Arabescato Onyx.jpg";
import WallHoneyArabescatoOnyx from "../images/Wall Honey Arabescato Onyx.jpg";

import WallLynxMarbel from "../marbels/Wall Lynx(111x59x31).jpg";
import WallLynx from "../images/Wall Lynx(111x59x31).jpg";

import WallPalissandroMarbel from "../marbels/Wall Palissandro(119x71x49).jpg";
import WallPalissandro from "../images/Wall Palissandro(119x71x49).jpg";

import WallGoldenFusionMarbel from "../marbels/wall Golden Fusion.jpg";
import WallGoldenFusion from "../images/wall Golden Fusion.jpg";

import WallVesuviusMarbel from "../marbels/Wall Vesuvius(53x73x26).jpg";
import WallVesuvius from "../images/Wall Vesuvius(53x73x26).jpg";

import FloorToiletMichelAngeloMarbel from "../marbels/Floor + Toilet Michel Angelo.jpg";
import FloorToiletMichelAngelo from "../images/Floor + Toilet Michel Angelo_.jpg";

import FloorToiletStimulusBrownMarbel from "../marbels/Floor Toilet Stimulus Brown.jpg";
import FloorToiletStimulusBrown from "../images/Floor Toilet Stimulus Brown_.jpg";

import FloorToiletRomanMarbel from "../marbels/Toilet Roman Basalt (2).jpg";
import FloorToiletRoman from "../images/Toilet Roman Basalt.jpg";

import FloorToiletRossoMarbel from "../marbels/Toilet Rosso Venato (2).jpg";
import FloorToiletRosso from "../images/Toilet Rosso Venato.jpg";

import FloorToiletSwanGreyMarbel from "../marbels/Toilet Swan Grey (112x72x22) (A50) (2).jpg";
import FloorToiletSwanGrey from "../images/Toilet Swan Grey (112x72x22) (A50).jpg";

import FloorToiletswarovskiWhiteMarbel from "../marbels/Toilet swarovski white(112x78x49) (2).jpg";
import FloorToiletswarovskiWhite from "../images/Toilet swarovski white(112x78x49).jpg";

import FloorToiletTempestMarbel from "../marbels/Toilet Tempest (2).jpg";
import FloorToiletTempest from "../images/Toilet Tempest.jpg";

import WallToiletExoticFusionMarbel from "../marbels/Wall Toilet Exotic Fusion.jpg";
import WallToiletExoticFusion from "../images/Wall Toilet Exotic Fusion_.jpg";

import WallToiletGrigioVeneziaMarbel from "../marbels/Wall Toilet Grigio Venezia.jpg";
import WallToiletGrigioVenezia from "../images/Wall Toilet Grigio Venezia_.jpg";

import WallToiletSaranColineMarbel from "../marbels/Wall Toilet Saran Coline (14) 89x42x23.jpg";
import WallToiletSaranColine from "../images/Wall Toilet Saran Coline (14) 89x42x23_.jpg";

import BlackBeautyLobbyMarbel from "../marbels/Black Beauty -  lobby wall.jpg";
import BlackBeautyLobby from "../images/Black Beauty -  lobby wall.jpg";

import BronzeArmaniMarbel from "../marbels/Bronze Armani - lobby flooring.jpg";
import BronzeArmani from "../images/Bronze Armani - lobby flooring.jpg";

import ExuberantLobbyMarbel from "../marbels/Exuberant - lobby flooring.png";
import ExuberantLobby from "../images/Exuberant - lobby flooring.jpg";

import MesopotamianLobbyMarbel from "../marbels/Mesopotamian - lobby floor.jpg";
import MesopotamianLobby from "../images/Mesopotamian - lobby floor.jpg";

import RammedEarthMarbel from "../marbels/Rammed Earth - lobby flooring.png";
import RammedEarth from "../images/Rammed Earth - lobby flooring.jpg";

import TemptationMarbel from "../marbels/Temptation Blue - lobby flooring.jpg";
import Temptation from "../images/Temptation Blue - lobby flooring.jpg";

import EmeraldQuartzMarbel from "../marbels/Emerald Quartz - washroom wall.jpg";
import EmeraldQuartz from "../images/Emerald Quartz - washroom wall.jpg";

import EnigmaMarbel from "../marbels/Enigma - flooring bahtroom.jpg";
import Enigma from "../images/Enigma - flooring bahtroom.jpg";

import GabrielGreyMarbel from "../marbels/Gabriel Grey - washroom flooring.jpg";
import GabrielGrey from "../images/Gabriel Grey - washroom flooring.jpg";

import GoldenPortoroMarbel from "../marbels/Golden Portoro - washroom wall.jpg";
import GoldenPortoro from "../images/Golden Portoro - washroom wall.jpg";

import silverTravertineMarbel from "../marbels/silver trentine.jpg";
import silverTravertine from "../images/Silver Travertine.jpg";

import exuberant2Marbel from "../marbels/exuberant2.jpg";
import Exuberant_02 from "../images/Exuberant_02.jpg";

export const array1 = [
  {
    id: 88,
    title: "Exuberant",
    dimension: "",
    type: "lobby",
    slabImage: exuberant2Marbel,
    image: Exuberant_02,
  },
  {
    id: 87,
    title: "Silver Travertine",
    dimension: "",
    type: "lobby",
    slabImage: silverTravertineMarbel,
    image: silverTravertine,
  },
  {
    id: 86,
    title: "Golden Portoro",
    dimension: "",
    type: "bathroom",
    slabImage: GoldenPortoroMarbel,
    image: GoldenPortoro,
  },
  {
    id: 85,
    title: "Gabriel Grey",
    dimension: "",
    type: "bathroom",
    slabImage: GabrielGreyMarbel,
    image: GabrielGrey,
  },
  {
    id: 84,
    title: "Enigma",
    dimension: "",
    type: "bathroom",
    slabImage: EnigmaMarbel,
    image: Enigma,
  },
  {
    id: 83,
    title: "Emerald Quartz",
    dimension: "",
    type: "bathroom",
    slabImage: EmeraldQuartzMarbel,
    image: EmeraldQuartz,
  },
  {
    id: 82,
    title: "Temptation Blue",
    dimension: "",
    type: "lobby",
    slabImage: TemptationMarbel,
    image: Temptation,
  },
  {
    id: 81,
    title: "Rammed Earth",
    dimension: "",
    type: "lobby",
    slabImage: RammedEarthMarbel,
    image: RammedEarth,
  },
  {
    id: 80,
    title: "Mesopotamian",
    dimension: "",
    type: "lobby",
    slabImage: MesopotamianLobbyMarbel,
    image: MesopotamianLobby,
  },
  {
    id: 79,
    title: "Exuberant",
    dimension: "",
    type: "lobby",
    slabImage: ExuberantLobbyMarbel,
    image: ExuberantLobby,
  },
  {
    id: 78,
    title: "Bronze Armani",
    dimension: "",
    type: "lobby",
    slabImage: BronzeArmaniMarbel,
    image: BronzeArmani,
  },
  {
    id: 77,
    title: "Black Beauty",
    dimension: "",
    type: "lobby",
    slabImage: BlackBeautyLobbyMarbel,
    image: BlackBeautyLobby,
  },
  {
    id: 75,
    title: "Toilet Saran Coline",
    dimension: "89x42x23",
    type: "bathroom",
    slabImage: WallToiletSaranColineMarbel,
    image: WallToiletSaranColine,
  },

  {
    id: 74,
    title: "Toilet Grigio Venezia",
    dimension: "",
    type: "bathroom",
    slabImage: WallToiletGrigioVeneziaMarbel,
    image: WallToiletGrigioVenezia,
  },

  {
    id: 73,
    title: "Toilet Exotic Fusion",
    dimension: "",
    type: "bathroom",
    slabImage: WallToiletExoticFusionMarbel,
    image: WallToiletExoticFusion,
  },

  {
    id: 72,
    title: "Toilet Tempest",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletTempestMarbel,
    image: FloorToiletTempest,
  },

  {
    id: 71,
    title: "Toilet Swarovski White",
    dimension: "112x78x49",
    type: "bathroom",
    slabImage: FloorToiletswarovskiWhiteMarbel,
    image: FloorToiletswarovskiWhite,
  },

  {
    id: 70,
    title: "Toilet Swan Grey",
    dimension: "112x72x22",
    type: "bathroom",
    slabImage: FloorToiletSwanGreyMarbel,
    image: FloorToiletSwanGrey,
  },

  {
    id: 69,
    title: "Toilet Golden Fusion",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletRossoMarbel,
    image: FloorToiletRosso,
  },

  {
    id: 68,
    title: "Toilet Roman Basalt ",
    dimension: "124x76",
    type: "bathroom",
    slabImage: FloorToiletRomanMarbel,
    image: FloorToiletRoman,
  },
  {
    id: 67,
    title: "Floor Toilet Stimulus Brown",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletStimulusBrownMarbel,
    image: FloorToiletStimulusBrown,
  },
  {
    id: 66,
    title: "Floor Toilet Michel Angelo",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletMichelAngeloMarbel,
    image: FloorToiletMichelAngelo,
  },

  {
    id: 65,
    title: "Wall Vesuvius",
    dimension: "53x73x26",
    type: "lobby",
    slabImage: WallVesuviusMarbel,
    image: WallVesuvius,
  },
  {
    id: 64,
    title: "Wall Golden Fusion",
    dimension: "",
    type: "lobby",
    slabImage: WallGoldenFusionMarbel,
    image: WallGoldenFusion,
  },
  {
    id: 63,
    title: "Wall Palissandro",
    dimension: "119x71x49",
    type: "lobby",
    slabImage: WallPalissandroMarbel,
    image: WallPalissandro,
  },
  {
    id: 62,
    title: "Wall Lynx",
    dimension: "111x59x31",
    type: "lobby",
    slabImage: WallLynxMarbel,
    image: WallLynx,
  },

  {
    id: 61,
    title: "Wall Honey Arabescato Onyx",
    dimension: "",
    type: "lobby",
    slabImage: WallHoneyArabescatoOnyxMarbel,
    image: WallHoneyArabescatoOnyx,
  },
  {
    id: 60,
    title: "Wall Black Onyx",
    dimension: "95x69x29",
    type: "lobby",
    slabImage: WallBlackOnyxMarbel,
    image: WallBlackOnyx,
  },
  {
    id: 59,
    title: "Wall Black Forest",
    dimension: "120x74x17",
    type: "lobby",
    slabImage: WallBlackForestMarbel,
    image: WallBlackForest,
  },
  {
    id: 58,
    title: "Wall Atlanta Grey",
    dimension: "97x61x62",
    type: "lobby",
    slabImage: WallAtlantaGreyMarbel,
    image: WallAtlantaGrey,
  },
  {
    id: 57,
    title: "Wall And Floor Arezzo",
    dimension: "83x71x62",
    type: "lobby",
    slabImage: WallAndFloorArezzoMarbel,
    image: WallAndFloorArezzo,
  },
  {
    id: 56,
    title: "Wall African Portoro",
    dimension: "",
    type: "lobby",
    slabImage: WallAfricanPortoroMarbel,
    image: WallAfricanPortoro,
  },

  {
    id: 55,
    title: "Floor Toilet Maverick",
    dimension: "85x67x86",
    type: "bathroom",
    slabImage: FloorToiletMaverick8Marbel,
    image: FloorToiletMaverick8,
  },

  {
    id: 54,
    title: "Floor Toilet Maverick",
    dimension: "69x58x34",
    type: "bathroom",
    slabImage: FloorToiletMaverick6Marbel,
    image: FloorToiletMaverick6,
  },

  {
    id: 53,
    title: "Floor Toilet Emal Grey",
    dimension: "117x70x48",
    type: "bathroom",
    slabImage: FloorToiletEmalgreyMarbel,
    image: FloorToiletEmalgrey,
  },
  // {
  //   id: 52,
  //   title: "Floor Toilet Emal Grey",
  //   dimension: "117x70x48",
  //   type: "bathroom",
  //   slabImage: FloorToiletEmalgreyMarbel,
  //   image: FloorToiletEmalgrey,
  // },

  {
    id: 51,
    title: "Floor Toilet Armani Grey",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletArmaniGreyMarbel,
    image: FloorToiletArmaniGrey,
  },

  {
    id: 50,
    title: "Floor Toilet Argent Grey",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletArgent_GreyMarbel,
    image: FloorToiletArgent_Grey,
  },
  {
    id: 49,
    title: "Floor Toilet Albanian White",
    dimension: "",
    type: "bathroom",
    slabImage: FloorToiletAlbanianWhiteMarbel,
    image: FloorToiletAlbanianWhite,
  },
  {
    id: 48,
    title: "Michael Angelo Marble",
    dimension: "103x72x65",
    type: "bedroom",
    slabImage: FloorMichealAngeloMarbel,
    image: FloorMichealAngelo,
  },
  {
    id: 47,
    title: "Floor Statuario Venato",
    dimension: "117x51x64",
    type: "bedroom",
    slabImage: FloorStatuarioVenatoMarbel,
    image: FloorStatuarioVenato,
  },

  {
    id: 46,
    title: "Floor Statuario Arabescato",
    dimension: "",
    type: "bedroom",
    slabImage: FloorStatuarioArabescatoMarbel,
    image: FloorStatuarioArabescato,
  },

  {
    id: 45,
    title: "Floor Sofia Grey",
    dimension: "78x66x33",
    type: "bedroom",
    slabImage: FloorSofiaGrey78x66x33Marbel,
    image: FloorSofiaGrey78x66x33,
  },

  {
    id: 44,
    title: "Floor Pegasus",
    dimension: "",
    type: "bedroom",
    slabImage: FloorPegasusMarbel,
    image: FloorPegasus,
  },

  {
    id: 43,
    title: "Floor Pegasus",
    dimension: "115x60x71",
    type: "bedroom",
    slabImage: FloorPegasus115x60x71Marbel,
    image: FloorPegasus115x60x71,
  },

  {
    id: 42,
    title: "Floor New Zealand Lasa",
    dimension: "120x76",
    type: "bedroom",
    slabImage: FloorNewZealandLasa120x76Marbel,
    image: FloorNewZealandLasa120x76,
  },

  {
    id: 41,
    title: "Floor Midnight Grey",
    dimension: "113x79x73",
    type: "bedroom",
    slabImage: FloorMidnightGrey113x79x73Marbel,
    image: FloorMidnightGrey113x79x73,
  },

  {
    id: 39,
    title: "Floor Michel Angelo",
    dimension: "125x6476",
    type: "bedroom",
    slabImage: FloorMichelAngelo125_x64_76Marbel,
    image: FloorMichelAngelo125_x64_76,
  },

  {
    id: 38,
    title: "Floor Mediterranean",
    dimension: "",
    type: "bedroom",
    slabImage: FloorMediterraneanMarbel,
    image: FloorMediterranean,
  },

  {
    id: 37,
    title: "Floor Mauranice",
    dimension: "",
    type: "bedroom",
    slabImage: FloorMauraniceMarbel,
    image: FloorMauranice,
  },

  {
    id: 36,
    title: "Floor Lagos",
    dimension: "96x67x87",
    type: "bedroom",
    slabImage: FloorLagosMarbel,
    image: FloorLagos,
  },

  {
    id: 35,
    title: "Floor Grigio Fresco",
    dimension: "",
    type: "bedroom",
    slabImage: FloorGrigioFrescoMarbel,
    image: FloorGrigioFresco,
  },

  {
    id: 34,
    title: "Floor Fior De Bosco",
    dimension: "",
    type: "bedroom",
    slabImage: FloorFiorDeBoscoMarbel,
    image: FloorFiorDeBosco,
  },

  {
    id: 33,
    title: "Floor Crema Siena",
    dimension: "90x62x77",
    type: "bedroom",
    slabImage: FloorCremaSiena90x62x77Marbel,
    image: FloorCremaSiena90x62x77,
  },

  {
    id: 32,
    title: "Floor Crema Europa",
    dimension: "84x73x87",
    type: "bedroom",
    slabImage: FloorCremaEuropa84x73x87Marbel,
    image: FloorCremaEuropa84x73x87,
  },
  {
    id: 31,
    title: "Floor Caspian Grey",
    dimension: "108x64x90",
    type: "bedroom",
    slabImage: FloorChanelGrey108x64x90Marbel,
    image: FloorChanelGrey108x64x90,
  },

  {
    id: 30,
    title: "Floor Chanel Grey",
    dimension: "101x75x15",
    type: "bedroom",
    slabImage: FloorChanelGrey101x75x15Marbel,
    image: FloorChanelGrey101x75x15,
  },

  {
    id: 29,
    title: "Floor Caspian Grey",
    dimension: "98x70x74",
    type: "bedroom",
    slabImage: FloorCaspianGrey98x70x74Marbel,
    image: FloorCaspianGrey98x70x74,
  },
  {
    id: 28,
    title: "Floor Blue Bardilio",
    dimension: "",
    type: "bedroom",
    slabImage: FloorBlueBardilioMarbel,
    image: FloorBlueBardilio,
  },
  {
    id: 27,
    title: "Floor Bianco Lasa",
    dimension: "118x56x75",
    type: "bedroom",
    slabImage: FloorBiancoLasa118x56x75Marbel,
    image: FloorBiancoLasa118x56x75,
  },
  {
    id: 26,
    title: "Floor Bianco Lasa",
    dimension: "",
    type: "bedroom",
    slabImage: FloorBiancoLasa1Marbel,
    image: FloorBiancoLasa1,
  },
  {
    id: 25,
    title: "Floor Bianco Lasa",
    dimension: "99x80x59",
    type: "bedroom",
    slabImage: FloorBiancoLasa99x80x59Marbel,
    image: FloorBiancoLasa99x80x59,
  },
  {
    id: 24,
    title: "Floor Bianco Lasa",
    dimension: "83x63x47",
    type: "bedroom",
    slabImage: FloorBiancoLasa83_x63_x47Marbel,
    image: FloorBiancoLasa83_x63_x47,
  },

  {
    id: 22,
    title: "Floor Bianco Lasa ",
    dimension: "117x78x58",
    type: "bedroom",
    slabImage: FloorBiancoLasa117x78x58Marbel,
    image: FloorBiancoLasa117x78x58,
  },
  {
    id: 21,
    title: "Floor Bianco Carrara",
    dimension: "126x70x86",
    type: "bedroom",
    slabImage: FloorBiancoCarraraMarbel,
    image: FloorBiancoCarrara,
  },
  {
    id: 20,
    title: "Floor Alexander Grey",
    dimension: "",
    type: "bedroom",
    slabImage: FloorAlexanderGreyMarbel,
    image: FloorAlexanderGrey,
  },
  {
    id: 19,
    title: "Floor Alexander Grey",
    dimension: "90x78x67",
    type: "bedroom",
    slabImage: floorAlexgendarGrey90x78x67Marbel,
    image: floorAlexgendarGrey90x78x67,
  },
  {
    id: 18,
    title: "Michael Engelo Room",
    dimension: "",
    type: "bedroom",
    slabImage: michaelengeloroomPageMarbel,
    image: michaelengeloroom_page,
  },
  {
    id: 17,
    title: "Wall Toilet Palissandro Blue",
    dimension: "121x66x51",
    type: "bathroom",
    slabImage: wallToiletPalissandroMarbel,
    image: wallToiletPalissandro,
  },
  {
    id: 16,
    title: "Wall Toilet Metal Rust",
    dimension: "",
    type: "bathroom",
    slabImage: WalltoiletMetalRustMarbel,
    image: WalltoiletMetalRust,
  },
  {
    id: 15,
    title: "Toilet Michael Grey",
    dimension: "97x61x31",
    type: "bathroom",
    slabImage: toiletMichealGreyMarbel,
    image: toiletMichealGrey,
  },
  {
    id: 14,
    title: "Toilet Grigio Smoke",
    dimension: "124x76",
    type: "bathroom",
    slabImage: toiletgrigioSmokeMarbel,
    image: toiletgrigioSmoke,
  },
  {
    id: 13,
    title: "Toilet Grey Orobico",
    dimension: "73x53x53",
    type: "bathroom",
    slabImage: toiletgreyOrobicoMarbel,
    image: toiletgreyOrobico,
  },

  {
    id: 11,
    title: "Toilet GOK grey",
    dimension: "104x64x55",
    type: "bathroom",
    slabImage: toiletGokgreyMarbel,
    image: toiletGokgrey,
  },
  {
    id: 10,
    title: "Toilet Feather Grey",
    dimension: "71x57x29",
    type: "bathroom",
    slabImage: ToiletFeatherGreyMarbel,
    image: ToiletFeatherGrey,
  },

  {
    id: 1,
    title: "Toilet Nero Cartier",
    dimension: "105x56x62",
    type: "bathroom",
    slabImage: toiletNeroCartier105Marbel,
    image: toiletNeroCartier105,
  },
  {
    id: 2,
    title: "Toilet Nero Galaxy",
    dimension: "73x118x9",
    type: "bathroom",
    slabImage: toiletneroGalaxyMarbel,
    image: toiletneroGalaxy,
  },

  {
    id: 3,
    title: "Toilet NSL-1",
    dimension: "",
    type: "bathroom",
    slabImage: toiletNslMarbel,
    image: toiletNsl,
  },

  {
    id: 4,
    title: "Toilet Platinum Portoro",
    dimension: "",
    type: "bathroom",
    slabImage: toiletPlatinumMarbel,
    image: toiletPlatinum,
  },
  {
    id: 5,
    title: "Wall Toilet Dolche Grey",
    dimension: "65x108x28",
    type: "bathroom",
    slabImage: WallToiletDolcheMarbel,
    image: WallToiletDolche,
  },
  // {
  //   id: 6,
  //   title: "Wall Toilet Martin Rust",
  //   dimension: "113x76x63",
  //   type: "bathroom",
  //   slabImage: WallToiletMartinRustMarbel,
  //   image: WallToiletMartinRust,
  // },
  {
    id: 7,
    title: "Wall Toilet Martin Grey",
    dimension: "113x76x63",
    type: "bathroom",
    slabImage: WallToiletMartingreyMarbel,
    image: WallToiletMartingrey,
  },
  {
    id: 8,
    title: "Wall Toilet Machi Vachi",
    dimension: "",
    type: "bathroom",
    slabImage: WallToiletMachiVachiMarbel,
    image: WallToiletMachiVachi,
  },

  {
    id: 9,
    title: "Toilet Statuario Oro",
    dimension: "111x73x66",
    type: "bathroom",
    slabImage: toiletSatuarioMarbel,
    image: toiletSatuario,
  },
];
