import React, { Fragment } from "react";
import logo from "../images/logo.webp";
const Header = () => {
  return (
    <Fragment>
      <div className="header">
        <div className="container">
          <div className="header_wrapper">
            <img src={logo} alt="logo" />
            <h2>Walk On Luxury</h2>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
