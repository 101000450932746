import React, { Fragment, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import { array1 } from "../productArray/array1";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

const Details = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [detail, setDetail] = useState(null);
  useEffect(() => {
    if (id) {
      const filter = array1?.find((data, idx) => data?.id == id);
      setDetail(filter);
    }
  }, [id]);
  return (
    <Fragment>
      {detail ? (
        <>
          <Header />
          <section className="details">
            <div className="container">
              <button className="back_btn" onClick={() => navigate("/")}>
                {/* <span>{`<=`}</span> */}
                <span>Go Back</span>
              </button>
              <div className="detail_card">
                <div className="banner">
                  <img src={detail?.slabImage} alt="banner_img" />
                </div>
                <h3>{detail?.title}</h3>
                <div className="three_d_view">
                  <ReactPhotoSphereViewer
                    src={detail?.image}
                    height={"700px"}
                    littlePlanet={false}
                    width={"100%"}
                    minFov={100}
                    maxFov={100}
                  />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <p>...Loading</p>
      )}
    </Fragment>
  );
};

export default Details;
