import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { array1 } from "../productArray/array1";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Products = () => {
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(4);
  const [seeMarbel, setSeeMarbel] = useState(false);

  const handleSeeMore = () => {
    setSeeMarbel(!seeMarbel);
    const filterData = array1?.filter((itm) => itm?.type === "bedroom");
    if (seeMore !== filterData?.length) {
      setSeeMore(filterData?.length);
    } else {
      setSeeMore(4);
    }
  };

  const [seeMoreBathRoom, setSeeMoreBathRoom] = useState(4);
  const [seeMarbelBathroom, setSeeMarbelBathroom] = useState(false);
  const [seeMoreLobby, setseeMoreLobby] = useState(4);
  const [seeMarbelLobby, setSeeMarbelLobby] = useState(false);
  const handleSeeMoreBathroom = () => {
    setSeeMarbelBathroom(!seeMarbelBathroom);
    const filterData = array1?.filter((itm) => itm?.type === "bathroom");
    if (seeMoreBathRoom !== filterData?.length) {
      setSeeMoreBathRoom(filterData?.length);
    } else {
      setSeeMoreBathRoom(4);
    }
  };

  const handleViewDetails = (data) => {
    const formattedTitle = data?.title?.replace(/ /g, "-");
    navigate(`/360-view/${formattedTitle}/${data?.id}`);
  };

  const handleSeeMoreLobby = () => {
    setSeeMarbelLobby(!seeMarbelLobby);
    const filterData = array1?.filter((itm) => itm?.type === "bathroom");
    if (seeMoreLobby !== filterData?.length) {
      setseeMoreLobby(filterData?.length);
    } else {
      setseeMoreLobby(4);
    }
  };

  return (
    <>
      <Header />

      <section className="product_listing">
        <div className="container">
          <div className="title">
            <h3>Bedroom Marble</h3>
            <button className="btn" onClick={() => handleSeeMore()}>
              {!seeMarbel ? "See All" : "See less"}
            </button>
          </div>
          <div className="products">
            {array1
              ?.filter((itm) => itm?.type === "bedroom")
              ?.slice(0, seeMore)
              ?.map((itm, idx) => (
                <div
                  className="product_card"
                  key={idx}
                  onClick={() => handleViewDetails(itm)}
                >
                  {/* <img
                    src={itm?.slabImage}
                    loading="lazy"
                    alt="product_image"
                  /> */}
                  <LazyLoad height={200} offset={100}>
                    <img src={itm?.slabImage} alt="product_image" />
                  </LazyLoad>
                  <pre>{itm?.dimension}</pre>
                  <h4>{itm?.title}</h4>
                </div>
              ))}
          </div>
        </div>
        <div className="container mt-5">
          <div className="title">
            <h3>Bathroom Marble</h3>
            <button className="btn" onClick={() => handleSeeMoreBathroom()}>
              {!seeMarbelBathroom ? "See All" : "See less"}
            </button>
          </div>
          <div className="products">
            {array1
              ?.filter((itm) => itm?.type === "bathroom")
              ?.slice(0, seeMoreBathRoom)
              ?.map((itm, idx) => (
                <div
                  className="product_card"
                  key={idx}
                  onClick={() => handleViewDetails(itm)}
                >
                  <LazyLoad height={200} offset={100}>
                    <img src={itm?.slabImage} alt="product_image" />
                  </LazyLoad>
                  <pre>{itm?.dimension}</pre>
                  <h4>{itm?.title}</h4>
                </div>
              ))}
          </div>
        </div>

        <div className="container mt-5">
          <div className="title">
            <h3>Lobby Marble</h3>
            <button className="btn" onClick={() => handleSeeMoreLobby()}>
              {!seeMarbelLobby ? "See All" : "See less"}
            </button>
          </div>
          <div className="products">
            {array1
              ?.filter((itm) => itm?.type === "lobby")
              ?.slice(0, seeMoreLobby)
              ?.map((itm, idx) => (
                <div
                  className="product_card"
                  key={idx}
                  onClick={() => handleViewDetails(itm)}
                >
                  <LazyLoad height={200} offset={100}>
                    <img src={itm?.slabImage} alt="product_image" />
                  </LazyLoad>
                  <pre>{itm?.dimension}</pre>
                  <h4>{itm?.title}</h4>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Products;
