import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:deepearls@deepearls.in";
  };

  const handlePhoneClick = (e, phoneNumber) => {
    e.preventDefault();
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <section className="footer">
      <div className="container">
        <div className="footer_txt">
          <Link to="/" onClick={handleEmailClick}>
            Email Us: deepearls@deepearls.in
          </Link>
          <Link to="/" onClick={(e) => handlePhoneClick(e, "+919818600020")}>
            Contact Us: +91 9818600020
          </Link>
          <Link to="/" onClick={(e) => handlePhoneClick(e, "+919999336772")}>
            Call Us: +91 9999336772
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
